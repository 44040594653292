import mxnAppointments from '@/mixins/appointments'
import mxnCards from '@/mixins/cards'
import mxnDoctors from '@/mixins/doctors'
import mxnTransactionTypes from '@/mixins/transactionTypes'
import mxnTransactions from '@/mixins/transactions'
import mxnBranches from '@/mixins/branches'
import mxnCategories from '@/mixins/categories'
import mxnSwal from '@/mixins/toast'
import GenericTable from './../GenericTable'
import { mapState, mapGetters } from 'vuex'
import moment from 'moment';
import _ from 'lodash'

export default {
    name: 'Appointment',
    mixins: [mxnAppointments, mxnTransactionTypes, mxnTransactions, mxnBranches, mxnSwal, mxnCategories],
    computed: {
        ...mapState([ 'userInfo', 'patientInfo']),
        ...mapGetters(['isPatient', 'uploadProgress', 'getAttachement', 'hasPermission']),
    },
    data() {
        return {
            dropDown: false,
            showModal: false,
            showTransactionModal: false,
            transactionDetails: {},
            appointmentDetails: {},
            branchList: [],
            rawTransactionTypeList: [],
            transactionTypeList: [],
            categoryList: [],
            rawTransactions: [],
            unSavedTransactions: [],
            transactions: [],
            notes: '',
            branch: '',
            description: '',
            transactionType: '',
            temporaryTransactionType: '',
            transactionUpdateIndex: null,
            appointmentId: '',
            transactionId: '',
            isTransactionUpdate: false,
            paymentReference: '',
            isProgress: false,
            assignedDoctor: '',
            enabledAssignDoctor: false,

            enabledDiscount: false,
            discountList : [{label: '5%', value: 0.05}, {label: '10%', value: 0.1}, {label: '20%', value: 0.2}, {label: '30%', value: 0.3}, {label: '40%', value: 0.4}, {label: '50%', value: 0.5}],
            discountName: '',
            discountPercentage: 0.2,

            enableMembership: false,
            cardRemainingConsultation: 0,
            cardDiscount: 0,
            cardDiscountLab: 0,
            cardDiscountDiag: 0,
            cardDiscountConsult: 0,
            patientCardDetails: {},
            enableCardDiscount: false,
            enableLabCardDiscount: false,
            enableDiagCardDiscount: false,
            enableConsultCardDiscount: false,
            enableCardConsultation: false,


            showAssignedDoctors: false,
            doctorsGta: {
                title: 'Doctors',
                search: {
                    placeholder: 'First Name or Last Name'
                },
                header: [
                    {name: "Last Name"},
                    {name: "First Name"},
                    {name: "Action"},
                ],
                body: [
                    {name: "user_lastname", type: 'text'},
                    {name: "user_firstname", type: 'text'},
                    {actions: [
                        {
                            className: 'fa-toggle-on text-green-400',
                            fn: this.updateAssignStatus,
                            show: this.showActiveChangeStatus
                        },
                        {
                            className: 'fa-toggle-off text-red-400',
                            fn: this.updateAssignStatus,
                            show: this.showDeactivatedChangeStatus
                        },
                    ]},
                ],
                query: this.getDoctorList,
                data: {},
                size: 10,
                payload: {
                    page: 1,
                    sortField: "user_lastname",
                    sortType: "ASC",
                    search: ''
                }
            },
            // gta

            gta: {
                search: {
                    show: false
                },
                header: [
                    {name: "Purpose of appointment"},
                    {name: "Date"},
                    {name: "Status"},
                    {name: "Action"},
                ],
                body: [
                    {name: "appointment_description", type: 'text'},
                    {name: "appointment_created", type: 'date'},
                    {name: "appointment_status", type: 'text'},
                    {actions: [
                        {
                            className: 'fa-edit text-blue-400',
                            fn: this.getAppointmentDetails,
                            show: this.isEditShow
                        },
                        {
                            className: 'fa-clipboard-check text-blue-400',
                            fn: this.updateAppointmentStatus
                        },
                        {
                            className: 'fa-print text-blue-400',
                            fn: this.printPatientDetails,
                            show: this.isPrintShow
                        }
                    ]},
                ],
                query: this.getAppointList,
                data: {},
                size: 10,
                payload: {
                    page: 1
                }
            }
        }
    },
    methods: {
        preLoader(act) {
            this.$store.dispatch('doPreLoader',act)
        },
        isEditShow(row) {
            return row.appointment_status === 'PENDING' && this.hasPermission(['appointments:create'])
        },
        isPrintShow(row) {
            // return ((row.appointment_status === 'PAID' && this.hasPermission(['appointments:print']))) || (row.appointment_status === 'PENDING' && this.hasPermission(['appointments:create']))
            return this.hasPermission(['appointments:print', 'appointments:create']);
        },
        getAppointList(str) {
            let _this = this;
            let params = _this.gta.payload;
            let id = _this.patientInfo.patient_id

            // TODO: do not proceed if no patient id
            if (!id) {
                return
            }
            // TODO: preloader
            _this.preLoader(true);

            mxnAppointments.getAppointmentsById(params, id).then(r => {
                _this.gta.data = r.data
                // TODO: end preloader
                setTimeout(() => {
                    _this.preLoader(false);
                }, 100)
            }, e => {
                console.info(e)
            })
        },
        getBranchList() {
            let _this = this;
            // TODO: preloader
            _this.preLoader(true);
            mxnBranches.doGetbranch().then(r => {
                _this.branchList = r.data
                // TODO: end preloader
                setTimeout(() => {
                    _this.preLoader(false);
                }, 100)
            }, e => {
                console.info(e)
            })
        },
        getTransactionList() {
            let _this = this;
            // TODO: preloader
            _this.preLoader(true);
            // _this.transactions = []
            mxnTransactions.getTransactionsByAppointmentId(_this.appointmentId).then(r => {
                let list = r.data || []
                list = list.map(i => {return {id: i.transaction_typeid, transactionId: i.transaction_id, name: i.transaction_name, cost: i.transaction_cost, status: i.transaction_status, note: i.transaction_notes}})
                // _this.clearTransactionForm();
                _this.constructTransactions(list)
                // TODO: end preloader
                setTimeout(() => {
                    _this.preLoader(false);
                }, 100)
            }, e => {
                console.info(e)
                // TODO: end preloader
                _this.preLoader(false);
            })
        },
        getCategoryList() {
            let _this = this;

            mxnCategories.doGetCategory().then(r => {
                _this.categoryList = r.data
            }, e => {
                console.info(e)
            })
        },
        getTransactionTypeList() {
            let _this = this;
            let params = {
                "search": '',
                "sortField": "type_name",
                "sortType": "DESC",
                "branch_id": _this.branch
            }
            _this.clearTransactionForm();

            mxnTransactionTypes.getTransactionTypesByBranchId(params).then(r => {
                let list = r.data.list || [];
                _this.rawTransactionTypeList = list;
                // _this.transactionTypeList = list.map(i => {return {id: i.type_id, name: i.type_name, cost: i.type_cost}})
                let tempList = _this.rawTransactions || []
                _this.transactionTypeList = [];
                _this.categoryList.map(i => {
                    let l = list.filter(details => details.type_categoryid == i.category_id) || [];
                    _this.transactionTypeList.push({
                        category_name: i.category_name,
                        category_id: i.category_id,
                        list: l.map(i => {return {id: i.type_id, name: i.type_name, cost: i.type_cost, description: i.type_description}})
                    })
                });

                _this.constructTransactions(tempList);

            }, e => {
                console.info(e)
                // TODO: end preloader
                _this.preLoader(false);
            })
        },
        constructTransactions(transactionList, isNew) {
            let _this = this;
            _this.transactions = [];
            _this.categoryList.map(i => {
                let l = _this.rawTransactionTypeList.filter(details => details.type_categoryid == i.category_id) || [];
                let t = transactionList.filter(details => l.some(i => details.transaction_typeid == i.type_id || details.id == i.type_id))
                if (t.length) {
                    // let temp = isNew ? t : t.map(i => {return {id: i.transaction_typeid, transactionId: i.transaction_id, name: i.transaction_name, cost: i.transaction_cost, status: i.transaction_status, note: i.transaction_notes}})
                    _this.transactions.push({ category_name: i.category_name });
                    _this.transactions = [..._this.transactions, ...t];
                }

            });

        },
        saveAppointmentForm(s) {
            let _this = this;
            let buildTransactions = [];
            _this.transactions.map(i => {if(i.id) buildTransactions.push({type_id:i.id})})
            // console.info(buildTransactions);return
            // return
            let params = {
                "appointment_patientid": _this.patientInfo.patient_id,
                "appointment_branchid": _this.branch,
                "appointment_description": _this.description,
                "timestamp": moment().toDate().getTime(),
                "transactions": buildTransactions,
                "appointment_discountlabel": '',
                "appointment_discountpercent": 0,
            }
            // if has discount
            if (_this.enabledDiscount) {

                const isValid = document.getElementById('grid-discount').checkValidity();
                if (!isValid) {
                    document.getElementById('grid-discount').reportValidity();
                    return
                }


                params.appointment_discountlabel = _this.discountName;
                params.appointment_discountpercent = _this.discountPercentage;
            }

            // membership card
            if (_this.enableMembership) {

                if (_this.enableCardConsultation)
                    params.card_freeconsultation = 'TRUE'

                if (_this.enableCardDiscount)
                    params.card_discount = 'TRUE'
                else if (_this.enableLabCardDiscount)
                    params.card_laboratorydiscount = 'TRUE'
                else if (_this.enableDiagCardDiscount)
                    params.card_diagnosticdiscount = 'TRUE'
                else if (_this.enableConsultCardDiscount)
                    params.card_consultationdiscount = 'TRUE'

                if (_this.enableCardConsultation || _this.enableCardDiscount || _this.enableLabCardDiscount || _this.enableDiagCardDiscount || _this.enableConsultCardDiscount)
                    params.card_id = _this.patientCardDetails.xref_cardid

            }

            // transaction
            if (!buildTransactions.length && !_this.enableCardConsultation) {
                mxnSwal.toast('error', "Please add atleast one (1) transaction type.")
                return;
            }

            // console.info(params);return;

            // TODO: preloader
            _this.preLoader(true);
            if (_this.appointmentId) {
                delete params.user_password
                // Create user
                mxnAppointments.updateAppointment(params, _this.appointmentId).then(r => {
                    let d = r.data;
                    mxnSwal.toast('success', d)
                    _this.viewForm();
                    // TODO: reload
                    // _this.getAppointList()
                }, e => {
                    console.info(e)
                    mxnSwal.toast('error', e)
                    _this.preLoader(false);
                });

                return false;
            }
            // Create user
            mxnAppointments.createAppointment(params).then(r => {
                let d = r.data;
                mxnSwal.toast('success', d.message)
                _this.viewForm();
                // TODO: reload
                // _this.getAppointList()
            }, e => {
                console.info(e)
                mxnSwal.toast('error', e)
                _this.preLoader(false);
            })

        },
        updateAppointmentTransaction(transact, index) {
            let _this = this;
            let params = {
                "appointment_id": _this.appointmentId,
                "type_name": transact.name,
                "type_id": transact.id,
                "type_cost": transact.cost,
                "timestamp": moment().toDate().getTime(),
            }
            // TODO: preloader
            _this.preLoader(true);
            if (_this.transactionId) {
                params.transaction_status = "PENDING";
                params.transaction_notes = "";
                mxnTransactions.updateTransaction(params, _this.transactionId).then(r => {
                    transact.transactionId = _this.transactionId
                    // TODO: if update
                    _this.$set(_this.rawTransactions, index, transact);
                    _this.constructTransactions(_this.rawTransactions)
                    _this.temporaryTransactionType = ''
                    // _this.getTransactionList()
                    _this.cleatTransactionType();
                    // TODO: end preloader
                    setTimeout(() => {
                        _this.preLoader(false);
                    }, 100)
                }, e => {
                    console.info(e)
                    // TODO: end preloader
                    _this.preLoader(false);

                })
            } else {
                mxnTransactions.createTransaction(params).then(r => {
                    let data = r.data;
                    transact.transactionId = data.transaction_id
                    transact.status = "PENDING"
                    // TODO: add
                    _this.rawTransactions.push(transact);
                    _this.constructTransactions(_this.rawTransactions)
                    // _this.clearTransactionForm();
                    // TODO: end preloader
                    setTimeout(() => {
                        _this.preLoader(false);
                    }, 100)
                }, e => {
                    console.info(e)
                    // TODO: end preloader
                    _this.preLoader(false);

                })
            }
        },
        submitForm() {
            this.$refs.submitAppointmentForm.click()
        },
        doClearForm() {
            let _this = this;
            _this.appointmentId = '';
            _this.description = '';
            _this.branch = _this.userInfo.role_dataaccess === 'BRANCH' ? _this.userInfo.user_branchid : '';
            _this.appointmentDetails = {}
            _this.paymentReference = '';

            _this.enabledDiscount = false;
            _this.discountName = '';
            _this.discountPercentage = 0.2;

            _this.enableMembership = false;
            _this.cardRemainingConsultation = 0;
            _this.cardDiscount = 0;
            _this.enableCardDiscount = false;
            _this.enableLabCardDiscount=false;
            _this.enableDiagCardDiscount=false;
            _this.enableConsultCardDiscount=false;
            _this.enableCardConsultation = false;
        },
        getAppointmentDetails(row) {
            // console.info(row)
            let _this = this;

            _this.viewForm()
            _this.clearTransactionForm()

            _this.appointmentId = row.appointment_id;
            _this.branch = row.appointment_branchid;
            _this.description = row.appointment_description;
            _this.paymentReference = row.appointment_paymentreference

            if (row.appointment_discountlabel) {
                _this.enabledDiscount = true;
                _this.discountName = row.appointment_discountlabel;
                _this.discountPercentage = row.appointment_discountpercent;
            }

            // membership
            if (row.xref_cardid) {
                _this.enableMembership = true;
                _this.cardRemainingConsultation = row.card_consultationlimit
                _this.cardDiscount = row.card_discountpercent;
                _this.cardDiscountLab = row.card_laboratorydiscount;
                _this.cardDiscountDiag = row.card_diagnosticdiscount;
                _this.cardDiscountConsult = row.card_consultationdiscount;

                // console.warn(row);
                // if (_this.enableCardConsultation) 
                    _this.enableCardConsultation = row.xref_freeconsultation === 'TRUE'
            
                // if (row.xref_discount _this.enableCardDiscount) 
                    _this.enableCardDiscount = row.xref_discount === 'TRUE'


                    _this.enableLabCardDiscount = row.xref_laboratorydiscount === 'TRUE'

                    _this.enableDiagCardDiscount = row.xref_diagnosticdiscount === 'TRUE'

                    _this.enableConsultCardDiscount = row.xref_consultationdiscount === 'TRUE'
                
            }

            // _this.$emit('doView')
            _this.rawTransactions = row.transactions.map(i => {return {id: i.transaction_typeid, transactionId: i.transaction_id, name: i.transaction_name, cost: i.transaction_cost, status: i.transaction_status, note: i.transaction_notes}})

            // _this.getTransactionTypeList()


            // _this.getTransactionList()
       },
        viewForm() {
            let _this = this;
            _this.doClearForm();
            _this.getTransactionTypeList()
            _this.getPatientCard()
            _this.showModal = !_this.showModal
            if (!_this.showModal) {
                _this.getAppointList();
                _this.transactions = [];
                _this.unSavedTransactions = [];
            }
            // _this.$emit('doView')
        },
        getTotalCost() {
            let total = 0;
            this.transactions.map(row => {total += parseFloat(row.cost) || 0})
            // return this.rawTransactions.reduce((a,b) => a + parseFloat(b.cost), 0)
            return total
        },
        getDiscountDetails() {
            let _this = this;
            let d = 0;
            if (_this.enableCardDiscount) {
                d = _this.cardDiscount
            } else if (_this.enableLabCardDiscount) {
                d = _this.cardDiscountLab
            } else if (_this.enableDiagCardDiscount) {
                d = _this.cardDiscountDiag
            } else if (_this.enableConsultCardDiscount) {
                d = _this.cardDiscountConsult
            }
            return d;
        },
        updateAppointmentStatus(row) {
            let _this = this;


            // TODO: for doctors
            if (_this.hasPermission(['doctors:patients']) && _this.hasPermission(['doctors:transactions'])) {
                _this.getDoctorsPatientTransactionByAppointment(row);
            } else {
                _this.getAppointmentDetails(row);
            }
            
            
            // console.info(row)
            _this.appointmentDetails = {
                id: row.appointment_id,
                desc: row.appointment_description,
                branch: _.find(_this.branchList, {branch_id: row.appointment_branchid}),
                status: row.appointment_status,
                total: row.totalTransactionCost,
                transactions: row.transactions,
                paymentreference: row.appointment_paymentreference,
            }
        },
        getDoctorsPatientTransactionByAppointment(row) {
            var _this = this;
            let rRow = row;
            // console.info(row);return
            let params = {
                appointment_id: row.appointment_id,
                page: 1,
                sortField: "",
                sortType: "DESC",
                search: ''
            }
            _this.preLoader(true);
            mxnDoctors.getDoctorsTransactionsByAppointments(params).then(r => {
                // console.info(r.data)

                rRow.transactions = r.data.list ? r.data.list : [];

                _this.getAppointmentDetails(rRow);
                
                // console.info(row)
                _this.appointmentDetails = {
                    id: row.appointment_id,
                    desc: row.appointment_description,
                    branch: _.find(_this.branchList, {branch_id: row.appointment_branchid}),
                    status: row.appointment_status,
                    total: row.totalTransactionCost,
                    transactions: rRow.transactions,
                    paymentreference: row.appointment_paymentreference,
                }
                // TODO: end preloader
                setTimeout(() => {
                    _this.preLoader(false);
                }, 100)
            }, e => {
                console.info(e)
                // TODO: end preloader
                _this.preLoader(false);

            })
        },
        saveTransactionType() {
            let _this = this;
            let details = {};
            _.some(_this.transactionTypeList, row => {
                details = _.find(row.list, {id: _this.transactionType}) || {}
                return details.id ? true : false
            })
            if (!_this.transactionType) {
                return
            }

            if (_this.appointmentId) {
                let index = _.findIndex(_this.rawTransactions, {id: _this.temporaryTransactionType})
                _this.updateAppointmentTransaction(details, index);
            } else if (_this.transactionUpdateIndex != null) {
                // TODO: if update
                let index = _.findIndex(_this.unSavedTransactions, {id: _this.temporaryTransactionType})
                _this.$set(_this.unSavedTransactions, index, details);
                _this.constructTransactions(_this.unSavedTransactions)
                _this.temporaryTransactionType = ''
            } else {
                // TODO: if new added
                _this.unSavedTransactions.push(details);
                _this.constructTransactions(_this.unSavedTransactions)
                // _this.getTransactionList()
            }
            // console.info(_this.transactions);
            _this.transactionUpdateIndex = null;
            _this.transactionType = ''
            _this.isTransactionUpdate = false;
        },
        updateTransaction(t, i) {
            let _this = this;
            // let transact = JSON.parse(JSON.stringify(t));
            // console.info(_.findIndex(_this.transactions, {id:t.id}), i)
            _this.transactionId = t.transactionId
            // delete t.transactionId;
            _this.transactionType = t.id
            _this.temporaryTransactionType = t.id
            _this.transactionUpdateIndex = _.findIndex(_this.transactions, {id:t.id});
            _this.isTransactionUpdate = true;
        },
        removeTransaction(row) {
            let _this = this;
            let id = row.id;
            if (row.transactionId && _this.appointmentId){
                mxnSwal.confirmation({
                    icon: 'warning',
                    title: "Are you sure you want to remove this transaction?",
                    msg: "This action can't be undone",
                    confirmed: () => {
                        _this.preLoader(true);
                        mxnTransactions.deleteTransaction(row.transactionId).then(r => {

                            let index = _.findIndex(_this.rawTransactions, {id:id})
                            _this.$delete(_this.rawTransactions, index)
                            _this.constructTransactions(_this.rawTransactions)
                            // TODO: end preloader
                            setTimeout(() => {
                                _this.preLoader(false);
                            }, 100)
                        }, e => {
                            console.info(e)
                            // TODO: end preloader
                            _this.preLoader(false);

                        })
                    }
                } )
            } else {
                let index = _.findIndex(_this.unSavedTransactions, {id:id});
                _this.$delete(_this.unSavedTransactions, index)
                _this.constructTransactions(_this.unSavedTransactions)
            }
        },
        clearTransactionForm() {
            let _this = this;
            _this.transactions = [];
            _this.transactionUpdateIndex = null;
            _this.transactionType = '';
            _this.temporaryTransactionType = ''

            if (!_this.appointmentId) {
                _this.rawTransactions = [];
            }

        },
        cleatTransactionType() {

            let _this = this;
            // let transact = JSON.parse(JSON.stringify(t));

            _this.transactionId = ''
            // delete t.transactionId;
            _this.transactionType = ''
            _this.temporaryTransactionType = ''
            _this.transactionUpdateIndex = null;
            _this.isTransactionUpdate = false;
        },
        closeConfirm() {
            let _this = this;
            let appointmentId = _this.appointmentDetails.id;
            mxnSwal.confirmation({
                icon: 'info',
                title: "Are you sure you want to close this appointment?",
                msg: "This action can't be undone",
                confirmed: () => {
                    _this.preLoader(true);
                    mxnAppointments.closeAppointment(appointmentId).then(r => {
                        mxnSwal.toast('success', r.data)
                        _this.viewForm();
                        // TODO: reload
                        // _this.getAppointList()
                        // TODO: end preloader
                        setTimeout(() => {
                            _this.preLoader(false);
                        }, 100)
                    }, e => {
                        console.info(e)
                        // TODO: end preloader
                        _this.preLoader(false);

                    })
                }
            } )
        },
        isShowType(id) {
            let _this = this;
            // TODO: hide if found
            return !_.some(_this.transactions, {id:id})
        },
        updateAppointmentPaymentStatus() {
            let _this = this;
            let params = {
                "appointment_paymentreference": _this.paymentReference,
                "timestamp": moment().toDate().getTime()
            }
            _this.preLoader(true);
            mxnAppointments.updateAppointmentPayment(params, _this.appointmentId).then(r => {
                mxnSwal.toast('success', r.data)
                _this.viewForm();
                // TODO: reload
                // _this.getAppointList()
                // TODO: end preloader
                setTimeout(() => {
                    _this.preLoader(false);
                }, 100)
            }, e => {
                console.info(e)
                // TODO: end preloader
                _this.preLoader(false);

            })
        },
        printPatientDetails (row){
          console.log(row);
          window.open("/server/pdf/appointment/" + (this.appointmentDetails.id || row.appointment_id));
        },
        
        getAge(bDate) {
            let day = parseInt(bDate?.split("/")[0]);
            let month = parseInt(bDate?.split("/")[1]);
            let year = parseInt(bDate?.split("/")[2]);
            var today = new Date();
            var birthDate = new Date(year+"/"+month+"/"+day);
            var age = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            return age;
        },
        // Transaction for diagnostics
        saveTransactionForm() {
            let _this = this;
            // console.info(_this.transactionDetails, _this.appointmentId);
            let transactionId = _this.transactionDetails.transactionId

            let params = {
                appointment_id: _this.appointmentId,
                type_name: _this.transactionDetails.name,
                type_id: _this.transactionDetails.id,
                type_cost: _this.transactionDetails.cost,
                transaction_notes: _this.notes,
                transaction_status: "COMPLETE",
                timestamp: moment().toDate().getTime(),
            }
            mxnSwal.confirmation({
                icon: 'info',
                title: "Are you sure you want to proceed to complete this transaction?",
                msg: "This action can't be undone",
                confirmed: () => {
                    // TODO: preloader
                    _this.preLoader(true);

                    mxnTransactions.updateTransaction(params, transactionId).then(r => {
                        mxnSwal.toast("success", r.data)
                        _this.notes = ""
                        _this.showTransactionModal = !_this.showTransactionModal;
                        _this.showModal = !_this.showModal
                        _this.transactionDetails = '';
                        _this.getTransactionList()
                    }, e => {
                        console.info(e)
                        // TODO: end preloader
                        _this.preLoader(false);

                    })
                }
            } )

        },
        transactionModal(row) {
            let _this = this;
            _this.showTransactionModal = !_this.showTransactionModal;
            _this.showModal = !_this.showModal
            _this.transactionDetails = row;
            _this.notes = row.note
            // console.info(row)
            if ( _this.showTransactionModal) {
                _this.$store.dispatch("getAttachement", row.transactionId);
            }

        },
        showDoctors() {
            let _this = this
            // console.info("details", details)
            _this.showAssignedDoctors = true;

            _this.getDoctorList();

        },
        getDoctorList(str) {
            let _this = this;
            _this.preLoader(true);

            let details = _this.transactionDetails;
            let params = _this.doctorsGta.payload;
            params.search = str || '';
            params.transaction_id = details.transactionId
            
            mxnDoctors.getTransactionDoctors(params).then(r => {
                // console.info(r)
                // _this.doctorList = r.list
                _this.doctorsGta.data = r.data

                setTimeout(() => {
                    _this.preLoader(false);
                }, 100)
            }, e => {
                mxnSwal.toast('error', e.response.data)
                _this.preLoader(false);
            })
        },
        updateAssignStatus(row) {
            let _this = this;
            // console.info(row);return
            let details = _this.transactionDetails;
            let params = {
                xref_userid: row.user_id,
                xref_transactionid: details.transactionId
            }

            if (row.xref_userid) {
                
                let fullname = row.user_lastname + ',' + row.user_firstname;
                let title = "Are you sure you want to unassign doctor " + fullname +"?";
                let message = "This action cannot be undone."
                mxnSwal.confirmation({
                    icon: 'warning',
                    title: title,
                    msg: message,
                    confirmed: () => {
                        _this.preLoader(true);
                        mxnDoctors.unassignTransactionDoctor(row.xref_id).then(r => {
                            mxnSwal.toast('success', r.data)
                            // this.preLoader(false);
                            this.getDoctorList();
                        }, e => {
                            console.info(e.response.data)
                            mxnSwal.toast('error', e.response.data)
                            this.preLoader(false);
                        })
                    }
                });

                return;
            }

            mxnDoctors.assignTransactionDoctor(params).then(r => {
                console.info(r)
                // _this.doctorsGta.data = r.data

                // if (_this.gta.data.total === 0 && _this.searchInput !== '') {
                mxnSwal.toast('success', r.data);

                _this.getDoctorList();
                // }
            }, e => {
                mxnSwal.toast('error', e.response.data)
                _this.preLoader(false);
            })

        },
        showActiveChangeStatus(row) {
            return row.xref_id != null
        },
        showDeactivatedChangeStatus(row) {
            return row.xref_id == null
        },
        
        handleFileUpload(event){
            let _this = this;
            let file = event.target.files[0];
            // console.info(file)
            let acceptedSize = 3072; // 3mb
            let size = Math.round(file.size / 1024);

            let acceptedFormat = ["jpg", "jpeg", "png", "pdf", "doc", "docx", "xls", "xlsx", "csv", "zip"]
            let splitFilename = file.name.split(".");
            let fileType = splitFilename.pop()
            let filename = splitFilename.join('')
            let found = false;

            if (acceptedFormat.indexOf(fileType) < 0) {
                found = true;
                mxnSwal.toast('error', "File format not accepted");
            } else if (acceptedSize < size) {
                found = true;
                mxnSwal.toast('error', "File size exceed 3MB");
            }

            if (found) {
                event.target.value = '';
                return;
            }
            // console.info(_this.transactionDetails)
            // return;
            _this.isProgress = true;

            let formData = new FormData();

            setTimeout(() => {
                formData.append('upload', file);
                formData.append('dms_transactionid', _this.transactionDetails.transactionId);
                formData.append('dms_patientid', _this.patientInfo.patient_id);
                formData.append('dms_name', filename);
                formData.append('dms_type', 'RESULT');
                formData.append('timestamp', moment().toDate().getTime());
                _this.$store.dispatch("uploadResultFile", formData);
                setTimeout(() => _this.isProgress = false, 100)
            }, 100)


            // TODO: clear data
            event.target.value = '';
        },
        removeAttachment(id) {
            let _this = this;
            mxnSwal.confirmation({
                icon: 'info',
                title: "Are you sure you want to remove this attachment?",
                msg: "This action can't be undone",
                confirmed: () => {
                    _this.$store.dispatch("removeUploadedFile", id);
                }
            } )
        },
        downloadAttachment(dms) {
            let _this = this;
            // console.info(dms);return
            _this.$store.dispatch("downloadUploadedFile", dms);
        },
        getPatientCard() {
            let _this = this;
            let id = _this.patientInfo.patient_id
            // console.warn(this.patientInfo)
            _this.patientCardDetails = {};
            _this.cardRemainingConsultation = 0
            _this.cardDiscount = 0
            _this.cardDiscountLab = 0
            _this.cardDiscountDiag = 0
            _this.cardDiscountConsult = 0

            mxnCards.getPatientCardById(id).then(r => {
                _this.patientCardDetails = r.data

                // TODO: check expiration
                var todayDate = moment(new Date(), 'DD-MM-YYYY');
                var pastDate = moment(new Date(parseInt(r.data.card_expiration)), 'DD-MM-YYYY');
                
                _this.patientCardDetails.isExpired = todayDate.isBefore(pastDate);
                _this.patientCardDetails.expiredMessage = 'Membership Card has expired on ' + (moment(new Date(parseInt(r.data.card_expiration))).format('MMM DD YYYY'))

                _this.cardRemainingConsultation = r.data.card_consultationlimit 
                _this.cardDiscount = r.data.card_discountpercent
                _this.cardDiscountLab = r.data.card_laboratorydiscount
                _this.cardDiscountDiag = r.data.card_diagnosticdiscount
                _this.cardDiscountConsult = r.data.card_consultationdiscount
            }, e => {
                // console.info(e.response.data)
                // mxnSwal.toast('error', e.response.data)
                this.preLoader(false);
            })
        },
        toggleDiscount() {
            let _this = this;
            _this.discountName='';
            _this.enableCardDiscount=false;
            _this.enableLabCardDiscount=false;
            _this.enableDiagCardDiscount=false;
            _this.enableConsultCardDiscount=false;
        },
        toggleMembership(v) {
            let _this = this;
            let l = ['enabledDiscount', 'enableCardDiscount', 'enableLabCardDiscount', 'enableDiagCardDiscount', 'enableConsultCardDiscount'];
            l.map(i => {if (i != v) _this[i] = false})
            
        }
    },
    mounted () {
        let _this = this;

        // TODO: load userlist
        _this.getAppointList();

        // TODO: get branch list
        _this.getBranchList();

        // TODO: get category list
        _this.getCategoryList();

        // TODO: get patient card
        _this.getPatientCard();
    },
    components: {
        GenericTable
    }
}
