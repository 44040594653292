import mxnPatients from '@/mixins/patients'
import mxnDoctors from '@/mixins/doctors'
import mxnDate from '@/mixins/date'
import mxnSwal from '@/mixins/toast'
import mxnCommon from '@/mixins/common'
import mxnCardTypes from '@/mixins/cardTypes'
import { mapState, mapGetters } from 'vuex'
import moment from 'moment';
import WebCam from './../WebCam'
import GenericTable from './../GenericTable'
import Appointments from './../Appointments'
import Chart from './../Chart'
import Notes from './../Notes'

export default {
    name: 'Patient',
    mixins: [mxnPatients, mxnSwal, mxnCommon, mxnDate, mxnDoctors],
    props: {
        isPopup: Boolean,
        isOpen: Boolean,
    },
    computed: {
        ...mapState([ 'userInfo', 'patientInfo']),
        ...mapGetters(['isPatient', 'hasPermission', 'branchList', 'roleList']),
        countryName() {
          let countryObj = this.countries.filter((c)=>{
            return c.country_id == this.patientInfo.patient_countryid;
          });

          return countryObj[0]?.country_name;
        },

        getCardType() {
            let types = this.cardTypeList.filter(i => {
                return this.patientInfo.membership_card && this.patientInfo.membership_card.card_typeid === i.type_id;
            });
            
            return types[0]?.type_name;
        },

        selectedRoleInfo(){

            let role = this.roleList.filter((r)=>{
              return r.role_id === this.userInfo.user_roleid;
            });
            console.log("ROLE---", role[0])
            return role[0] || {
              role_dataaccess: 'GLOBAL',
              role_departmentaccess: 'GLOBAL'
            };
          },

        branchSelection() {
            let _this = this;
            let branchSelectionList = [];
            // if (newList.length !== oldList.length) {
                // console.info("new", newList)
            // let branches = newList;

            branchSelectionList = [{
                branch_id: 0,
                branch_name: "All Branches"
            }];

            branchSelectionList = [
                ...branchSelectionList,
                ..._this.branchList,
                {
                branch_id: -1,
                branch_name: "UNASSIGNED"
                }
            ];

            // TODO: initialize first selection

            return branchSelectionList || [];
        }
    },
    data() {
        return {
            dropDown: false,
            showModalPatient: false,
            dateFormatter: "dd/MM/yyyy",
            isShowPatientForm: false,
            isShowPatientDetails: false,
            patientList: [],
            // typeList: ['ADMIN','FRONTDESK','DIAGNOSTIC','BILLING','RELEASING','DOCTOR','PATIENT'],
            civilStatusList: ['Single','Married','Divorced','Separated','Widowed'],
            countries: [],
            department: [],
            cardTypeList: [],
            referenceId: '',
            patientId: '',
            firstName: '',
            lastName: '',
            middleName: '',
            photo: '',
            birthdate: '',
            gender: '',
            civilStatus: '',
            address: '',
            barangay: '',
            city: '',
            province: '',
            zipcode: '',
            email: '',
            mobile: '',
            phone: '',
            country: '174',
            branch: '',
            branchForm: '',
            // gta
            searchInput: '',
            gta: {
                title: 'Patient List',
                search: {
                    placeholder: 'Reference No., First Name or Last Name'
                },
                header: [
                    {name: "Reference Number"},
                    {name: "First Name", sortable: true, field: 'patient_firstname'},
                    {name: "Last Name", sortable: true, field: 'patient_lastname'},
                    {name: "Date Registered", sortable: true, field: 'patient_created'},
                    {name: "Card"},
                    {name: "Action"},
                ],
                body: [
                    {name: "patient_referenceid", type: 'text'},
                    {name: "patient_firstname", type: 'text'},
                    {name: "patient_lastname", type: 'text'},
                    {name: "patient_created", type: 'date'},
                    {actions: [
                        {
                            className: 'fa-id-card text-blue-400 pointer-events-none',
                            show: (row) => {
                                return row.membership_card ? true : false
                            },
                            fn: () => {}
                        }]},
                    {actions: [
                        {
                            className: 'fa-user-edit text-blue-400',
                            fn: (row) => {
                                this.doGetInfo(row, 'update')
                            },
                            show: () => {
                                return this.hasPermission(['patients:update'])
                            }
                        },
                        {
                            className: 'fa-info-circle text-blue-400',
                            fn: (row) => {
                                this.doGetInfo(row, 'view')
                            }
                        },
                        {
                            className: 'fa-trash text-red-400',
                            show: () => {
                                return this.hasPermission(['patients:delete'])
                            },
                            fn: this.removePatient
                        }
                    ]},
                ],
                query: this.getPatientList,
                data: {},
                size: 10,
                payload: {
                    page: 1,
                    sortField: "patient_created",
                    sortType: "DESC",
                    search: this.searchInput
                }
            },
            showAssignedDoctors: false,
            doctorsGta: {
                title: 'Doctors',
                search: {
                    placeholder: 'First Name or Last Name'
                },
                header: [
                    {name: "Last Name"},
                    {name: "First Name"},
                    {name: "Action"},
                ],
                body: [
                    {name: "user_lastname", type: 'text'},
                    {name: "user_firstname", type: 'text'},
                    {actions: [
                        {
                            className: 'fa-toggle-on text-green-400',
                            fn: this.updateAssignStatus,
                            show: this.showActiveChangeStatus
                        },
                        {
                            className: 'fa-toggle-off text-red-400',
                            fn: this.updateAssignStatus,
                            show: this.showDeactivatedChangeStatus
                        },
                    ]},
                ],
                query: this.getDoctorList,
                data: {},
                size: 10,
                payload: {
                    page: 1,
                    sortField: "user_lastname",
                    sortType: "ASC",
                    search: ''
                }
            }
        }
    },
    methods: {
        preLoader(act) {
            this.$store.dispatch('doPreLoader',act)
        },
        getPatientList(str) {
            let _this = this;
            let params = _this.gta.payload;
            params.search = str || '';
            // _this.searchInput = params.search;

            // TODO: prevent search on empty string
            /* if ((str === '' || str === undefined) && !_this.hasPermission(['users:create'])) {
                _this.gta.data = {};
                return
            } */
            // TODO: if the role dataaccesss if branch, user should be view their patient only on their branch
            params.branch_id = _this.branch


            // TODO: preloader
            _this.preLoader(true);
            let defaultUserForDoctor = _this.hasPermission(['doctors:patients']) ? 'doctorsGetPatients' : 'doGetPatients';

            mxnPatients[defaultUserForDoctor](params).then(r => {
                _this.gta.data = r.data

                // if (_this.gta.data.total === 0 && _this.searchInput !== '') {
                //     mxnSwal.toast('info', "No patient record found.")
                // }
                // TODO: end preloader
                setTimeout(() => {
                    _this.preLoader(false);
                }, 100)
            }, e => {
                console.info(e)
            })
        },
        getCountries() {
            let _this = this;
            mxnCommon.doGetCountries().then(r => {
                // console.info("countries List: ", r.data)
                _this.countries = r.data
            }, e => {
                console.info(e)
            })
        },
        saveUserForm(s) {
            let _this = this;
            // console.info("email:", _this.email)
            // return
            let params = {
                "patient_firstname": _this.firstName,
                "patient_middlename": _this.middleName,
                "patient_lastname": _this.lastName,
                "patient_photo": _this.photo,
                "patient_birthdate": _this.birthdate,
                "patient_gender": _this.gender,
                "patient_marital": _this.civilStatus,
                // "patient_countryid": _this.country,
                "patient_countryid": 174, // Default to Philippines
                "patient_address": _this.address,
                "patient_city": _this.city,
                "patient_province": _this.province,
                "patient_barangay": _this.barangay,
                "patient_zipcode": _this.zipcode,
                "patient_phone": _this.phone,
                "patient_mobile": _this.mobile,
                "user_branchid": _this.branchForm,
                "timestamp": moment().toDate().getTime()
            }

            // _this.userForm();
            // TODO: preloader
            _this.preLoader(true);
            if (_this.patientId) {
                params.patient_userid = _this.patientUserId
                // Create user
                mxnPatients.doUpdatePatient(params, _this.patientId).then(r => {
                    // console.info("User List: ", r.data)
                    let d = r.data;
                    mxnSwal.toast('success', d)
                    _this.viewForm();
                    // TODO: reload
                    _this.getPatientList();

                    // TODO: show details
                    _this.doGetInfo({}, 'view')
                }, e => {
                    console.info(e)
                    mxnSwal.toast('error', e)
                    _this.preLoader(true);
                });

                return false;
            }
            // Create user
            mxnPatients.doCreatePatient(params).then(r => {
                // console.info("User List: ", r.data)
                let d = r.data;
                mxnSwal.toast('success', d.message)
                _this.viewForm();
                // TODO: reload
                _this.getPatientList()

                // TODO: get ref
                _this.referenceId = d.patient_referenceid;
                // TODO: show details
                _this.doGetInfo({}, 'view')
            }, e => {
                console.info(e)
                mxnSwal.toast('error', e)
                _this.preLoader(true);
            })

        },
        doSubmitForm() {
            this.$refs.submitUserForm.click()
        },
        doClearForm() {
            let _this = this;
            _this.patientId = '';
            _this.firstName = '';
            _this.lastName = '';
            _this.middleName = '';
            _this.photo = '';
            _this.birthdate = '';
            _this.gender = '';
            _this.civilStatus = '';
            _this.barangay = '';
            _this.address = '';
            _this.city = '';
            _this.province = '';
            _this.zipcode = '';
            // _this.email = '';
            _this.mobile = '';
            _this.phone = '';
            _this.country = '';
            _this.branchForm = _this.selectedRoleInfo.role_dataaccess === 'GLOBAL' ? '' : _this.userInfo.user_branchid;
            // console.info(_this.branchForm)
            this.$store.dispatch('doStorePatient', {})
        },
        doGenerateDetails() {
            // console.info(row)
            let _this = this;
            let details = _this.patientInfo;
            _this.patientUserId = details.patient_userid;
            _this.patientId = details.patient_id;
            _this.firstName = details.patient_firstname;
            _this.lastName = details.patient_lastname;
            _this.middleName = details.patient_middlename;
            _this.photo = details.patient_photo;
            _this.birthdate = details.patient_birthdate;
            _this.gender = details.patient_gender;
            _this.civilStatus = details.patient_marital;
            _this.barangay = details.patient_barangay;
            _this.address = details.patient_address;
            _this.city = details.patient_city;
            _this.province = details.patient_province;
            _this.zipcode = details.patient_zipcode;
            // _this.email = details.email;
            _this.mobile = details.patient_mobile;
            _this.phone = details.patient_phone;
            _this.country = details.patient_countryid;
            _this.branchForm = details.patient_branchid;

            // _this.civilStatus = row.user_id;

            _this.$emit('doView')
            _this.isShowPatientForm = true;

        },
        doGetInfo(row, action) {
            // console.info(row)
            let _this = this;
            // this.patientInfo = row
            _this.referenceId = row.patient_referenceid ? row.patient_referenceid : _this.referenceId;
            // TODO: preloader
            _this.preLoader(true);
            mxnPatients.doViewPatient(_this.referenceId).then(r => {
                // console.info("User List: ", r.data)
                let d = r.data;
                // _this.patientInfo = d;
                this.$store.dispatch('doStorePatient',d)

                if (action === 'update') {
                    _this.doGenerateDetails()
                } else if (action === 'view') {
                    _this.isShowPatientDetails = true
                }

                // mxnSwal.toast('success', d)
                setTimeout(() => {
                    // TODO: generate input date
                    mxnDate.inputDate("input-date")

                    _this.preLoader(false);
                }, 100)
            }, e => {
                console.info(e)
                mxnSwal.toast('error', e)
                _this.preLoader(false);

            });
        },
        validateEmail(value) {
            // if the field is not a valid email
            const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
            return regex.test(value);
        },
        selectText(node) {
            let text = node.target.innerHTML;
            // console.info(node, text)
            // document.execCommand('copy')
            // return
            if (window.clipboardData && window.clipboardData.setData) {
                // TODO: notify user
                mxnSwal.toast('success', 'Copied to clipboard!')
                // IE specific code path to prevent textarea being shown while dialog is visible.
                return clipboardData.setData("Text", text);

            } else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
                var textarea = document.createElement("textarea");
                textarea.textContent = text;
                textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in MS Edge.
                document.body.appendChild(textarea);
                textarea.select();
                // TODO: notify user
                mxnSwal.toast('success', 'Copied to clipboard!')
                try {
                    return document.execCommand("copy");  // Security exception may be thrown by some browsers.
                } catch (ex) {
                    console.warn("Copy to clipboard failed.", ex);
                    return false;
                } finally {
                    document.body.removeChild(textarea);
                }
            }
        },
        viewForm(v) {
            let _this = this;
            _this.doClearForm();

            if (v === 'new') {
                _this.searchInput = ''
                // _this.gta.data = {};
                _this.isShowPatientForm = true;
                _this.isShowPatientDetails = false
            } else {
                _this.isShowPatientForm = !_this.isShowPatientForm
            }
            // TODO: init date
            setTimeout(function() {mxnDate.inputDate("input-date")})

        },
        /* doSearch(e) {
            let _this = this;
            // console.info(e.keyCode)
            if (e.keyCode === 13 && _this.searchInput !== '') {
                // console.info(_this.searchInput);
                _this.getPatientList(_this.searchInput)
            } else if (e.keyCode && _this.searchInput === '') {
                _this.gta.data = [];
            }
        }, */
        getPhotoBase64(data) {
            this.photo = data;
        },
        closeViewDetail() {
            let _this = this;

            _this.isShowPatientDetails = false


            this.$store.dispatch('doStorePatient',{})
            _this.referenceId = ''
        },
        getAge(bDate) {
            let day = parseInt(bDate?.split("/")[0]);
            let month = parseInt(bDate?.split("/")[1]);
            let year = parseInt(bDate?.split("/")[2]);
            var today = new Date();
            var birthDate = new Date(year+"/"+month+"/"+day);
            var age = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            return age;
        },
        removePatient(row) {
            let _this = this;
            let fullname = row.patient_lastname + ',' + row.patient_firstname;
            let title = "Are you sure you want to delete patient: " + fullname + " (" + row.patient_referenceid + ") ?";
            let message = "This will also delete all records such as appointments, transactions and diagnostic results of the patient."
            mxnSwal.confirmation({
                icon: 'warning',
                title: title,
                msg: message,
                confirmed: () => {
                    _this.preLoader(true);
                    mxnPatients.removePatient(row.patient_id).then(r => {
                        mxnSwal.toast('success', r.data)
                        // this.preLoader(false);
                        this.getPatientList();
                    }, e => {
                        console.info(e.response.data)
                        mxnSwal.toast('error', e.response.data)
                        this.preLoader(false);
                    })
                }
            })
        },
        showDoctors() {
            let _this = this
            // console.info("details", details)
            _this.showAssignedDoctors = true;

            _this.getDoctorList();

        },
        getDoctorList(str) {
            let _this = this;
            _this.preLoader(true);

            let details = _this.patientInfo;
            let params = _this.doctorsGta.payload;
            params.search = str || '';
            params.patient_id = details.patient_id

            mxnDoctors.getPatientDoctors(params).then(r => {
                // console.info(r)
                _this.doctorsGta.data = r.data

                // if (_this.gta.data.total === 0 && _this.searchInput !== '') {
                //     mxnSwal.toast('info', "No patient record found.")
                // }
                // TODO: end preloader
                setTimeout(() => {
                    _this.preLoader(false);
                }, 100)
            }, e => {
                mxnSwal.toast('error', e.response.data)
                _this.preLoader(false);
            })
        },
        updateAssignStatus(row) {
            let _this = this;
            // console.info(row)
            let details = _this.patientInfo;
            let params = {
                xref_userid: row.user_id,
                xref_patientid: details.patient_id
            }

            if (row.xref_userid) {

                let fullname = row.user_lastname + ',' + row.user_firstname;
                let title = "Are you sure you want to unassign doctor " + fullname +"?";
                let message = "This action cannot be undone."
                mxnSwal.confirmation({
                    icon: 'warning',
                    title: title,
                    msg: message,
                    confirmed: () => {
                        _this.preLoader(true);
                        mxnDoctors.unassignPatientDoctor(row.xref_id).then(r => {
                            mxnSwal.toast('success', r.data)
                            // this.preLoader(false);
                            this.getDoctorList();
                        }, e => {
                            console.info(e.response.data)
                            mxnSwal.toast('error', e.response.data)
                            this.preLoader(false);
                        })
                    }
                });

                return;
            }

            mxnDoctors.assignPatientDoctor(params).then(r => {
                console.info(r)
                // _this.doctorsGta.data = r.data

                // if (_this.gta.data.total === 0 && _this.searchInput !== '') {
                mxnSwal.toast('success', r.data);

                _this.getDoctorList();
                // }
            }, e => {
                mxnSwal.toast('error', e.response.data)
                _this.preLoader(false);
            })

        },
        showActiveChangeStatus(row) {
            return row.xref_id != null
        },
        showDeactivatedChangeStatus(row) {
            return row.xref_id == null
        },
        getCardTypeList() {
            let _this = this;

            let params = {
                page: 1,
                sortField: "type_name",
                sortType: "ASC",
                search: ''
            };

            mxnCardTypes.doGetCardType(params).then(r => {
                _this.cardTypeList = r.data.list || [];
            }, e => {
                console.info(e)
            })
        },
    },
    mounted () {
        let _this = this;

        _this.branch = _this.userInfo.role_dataaccess === 'BRANCH' ? _this.userInfo.user_branchid : 0;

        // TODO: get department
        if (_this.isPatient) {
            // console.info({patient_referenceid: _this.userInfo.user_email})
            let p = {patient_referenceid: _this.userInfo.user_email};
            _this.doGetInfo(p, 'view')
        } else {
            // TODO: load patientList
            _this.getPatientList();

        }
        // console.info(_this.patientInfo)

        _this.getCountries();

        _this.getCardTypeList();

    },
    components: {
        WebCam,
        GenericTable,
        Appointments,
        Chart,
        Notes,
    }
}
